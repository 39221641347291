import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "my-4 px-10 py-1" }
const _hoisted_2 = { class: "flex space-x-4 mb-3" }
const _hoisted_3 = { class: "font-bold" }
const _hoisted_4 = { class: "container my-3 py-3 px-10" }
const _hoisted_5 = { class: "w-full 2xl:py-5 py-3 flex justify-between items-center mb-2" }
const _hoisted_6 = {
  class: "text-2xl 2xl:text-4xl font-bold",
  "data-aos": "fade-right"
}
const _hoisted_7 = { class: "grid grid-cols-10 gap-5" }
const _hoisted_8 = { class: "col-span-2" }
const _hoisted_9 = ["onClick"]
const _hoisted_10 = { class: "col-span-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ol", _hoisted_2, [
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, { to: "/" }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("Home")
          ])),
          _: 1
        })
      ]),
      _cache[2] || (_cache[2] = _createElementVNode("li", null, ">", -1)),
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, { to: `/user` }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode(" User Management ")
          ])),
          _: 1
        })
      ]),
      _cache[3] || (_cache[3] = _createElementVNode("li", null, ">", -1)),
      _createElementVNode("li", _hoisted_3, [
        _createVNode(_component_router_link, { to: "" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($setup.UserId ? "Edit User" : "Create New User"), 1)
          ]),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("h1", _hoisted_6, _toDisplayString($setup.UserId ? "Edit User" : "Create New User"), 1)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.values($setup.HASH_EDIT_USER_INFORMATION), (value) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "inline-block mb-3",
              key: value
            }, [
              _createElementVNode("button", {
                type: "button",
                class: _normalizeClass(['green-tab-box', $setup.route.hash === `#${value}` && 'active']),
                onClick: _withModifiers(($event: any) => ($setup.handleTabClick(value)), ["prevent"])
              }, _toDisplayString($setup.computeTabName($setup.TAB_EDIT_USER_INFORMATION, value)), 11, _hoisted_9)
            ]))
          }), 128))
        ]),
        _createElementVNode("div", _hoisted_10, [
          (
              $setup.route.hash === `#${$setup.HASH_EDIT_USER_INFORMATION.CHANGE_INFORMATION}`
            )
            ? (_openBlock(), _createBlock($setup["TenantUserCRUD"], {
                key: 0,
                class: "box",
                data: $setup.tenantUserData
              }, null, 8, ["data"]))
            : (_openBlock(), _createBlock($setup["TenantUserUpdatePassword"], {
                key: 1,
                class: "box",
                data: $setup.tenantUserData
              }, null, 8, ["data"]))
        ])
      ])
    ])
  ]))
}