import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, mergeProps as _mergeProps, vShow as _vShow, withModifiers as _withModifiers, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = { class: "flex justify-end items-center border-t-2 2xl:space-x-5 space-x-3 2xl:py-5 py-3 mt-10" }
const _hoisted_4 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["Form"], {
      as: "el-form",
      onSubmit: $setup.onSubmit,
      "label-position": "top"
    }, {
      default: _withCtx(({ isSubmitting }) => [
        _cache[6] || (_cache[6] = _createElementVNode("p", { class: "text-2xl" }, "Password", -1)),
        _createElementVNode("div", {
          class: _normalizeClass([
          'flex justify-start items-center 2xl:space-x-5 space-x-3 2xl:py-5 py-3',
          isSubmitting && 'isSubmitting-rounded',
        ])
        }, [
          _createVNode(_component_el_switch, {
            size: "large",
            modelValue: $setup.IsAutogenerate,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.IsAutogenerate) = $event))
          }, null, 8, ["modelValue"]),
          _cache[5] || (_cache[5] = _createElementVNode("p", null, "Auto Generate Password", -1))
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass([
          'absolute bottom-2 right-6 w-full flex justify-end border-t-2 2xl:space-x-5 space-x-3 2xl:py-5 py-3',
          $setup.IsAutogenerate ? 'visible' : 'invisible',
        ])
        }, [
          _createElementVNode("button", {
            class: "btn btn--green-primary",
            disabled: isSubmitting,
            type: "submit"
          }, " Submit ", 8, _hoisted_2)
        ], 2)
      ]),
      _: 1
    }),
    _createVNode($setup["Form"], {
      as: "el-form",
      "validation-schema": $setup.schema,
      onSubmit: $setup.onSubmit,
      "label-position": "top",
      class: _normalizeClass(!$setup.IsAutogenerate ? 'visible' : 'invisible')
    }, {
      default: _withCtx(({ isSubmitting }) => [
        _createElementVNode("div", null, [
          _createElementVNode("div", {
            class: _normalizeClass([
            'grid grid-cols-1 lg:grid-cols-2 gap-16 xl:gap-28',
            isSubmitting && 'isSubmitting-rounded',
          ])
          }, [
            _createVNode($setup["Field"], { name: "NewPassword" }, {
              default: _withCtx(({ value, field, errorMessage }) => [
                _createVNode(_component_el_form_item, {
                  class: "inputfield relative",
                  error: errorMessage,
                  label: "Password"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, _mergeProps({
                      placeholder: "",
                      type: $setup.isPassword
                    }, field, {
                      "model-value": value,
                      autocomplete: "new-password"
                    }), null, 16, ["type", "model-value"]),
                    _withDirectives(_createVNode($setup["BaseSvgIcon"], {
                      class: "w-6 h-6 absolute top-1/2 right-4 transform -translate-y-1/2 cursor-pointer text-gray-500",
                      name: "akar-icons_eye-closed",
                      onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => ($setup.handleShowPassword('text', 'isPassword')), ["prevent"]))
                    }, null, 512), [
                      [_vShow, $setup.isPassword === 'password']
                    ]),
                    _withDirectives(_createVNode($setup["BaseSvgIcon"], {
                      class: "w-6 h-6 absolute top-1/2 right-4 transform -translate-y-1/2 cursor-pointer text-gray-500",
                      name: "akar-icons_eye",
                      onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => ($setup.handleShowPassword('password', 'isPassword')), ["prevent"]))
                    }, null, 512), [
                      [_vShow, $setup.isPassword === 'text']
                    ])
                  ]),
                  _: 2
                }, 1032, ["error"])
              ]),
              _: 1
            }),
            _createVNode($setup["Field"], { name: "ConfirmNewPassword" }, {
              default: _withCtx(({ value, field, errorMessage }) => [
                _createVNode(_component_el_form_item, {
                  class: "inputfield relative",
                  error: errorMessage,
                  label: "Confirm Password"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_input, _mergeProps({
                      placeholder: "",
                      type: $setup.isConfirmPassword
                    }, field, {
                      "model-value": value,
                      autocomplete: "confirm-password"
                    }), null, 16, ["type", "model-value"]),
                    _withDirectives(_createVNode($setup["BaseSvgIcon"], {
                      class: "w-6 h-6 absolute top-1/2 right-4 transform -translate-y-1/2 cursor-pointer text-gray-500",
                      name: "akar-icons_eye-closed",
                      onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => ($setup.handleShowPassword('text', 'isConfirmPassword')), ["prevent"]))
                    }, null, 512), [
                      [_vShow, $setup.isConfirmPassword === 'password']
                    ]),
                    _withDirectives(_createVNode($setup["BaseSvgIcon"], {
                      class: "w-6 h-6 absolute top-1/2 right-4 transform -translate-y-1/2 cursor-pointer text-gray-500",
                      name: "akar-icons_eye",
                      onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (
                  $setup.handleShowPassword('password', 'isConfirmPassword')
                ), ["prevent"]))
                    }, null, 512), [
                      [_vShow, $setup.isConfirmPassword === 'text']
                    ])
                  ]),
                  _: 2
                }, 1032, ["error"])
              ]),
              _: 1
            })
          ], 2),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("button", {
              class: "btn btn--green-primary",
              type: "submit",
              disabled: isSubmitting
            }, " Submit ", 8, _hoisted_4)
          ])
        ])
      ]),
      _: 1
    }, 8, ["validation-schema", "class"])
  ]))
}