import { defineComponent as _defineComponent } from 'vue'
import { ITenantUserUpdate } from "@/models/Tenant";
import { IUserVerificationDTO } from "@/models/User";
import TenantUserModule from "@ems/containers/Tenant/TenantUser.module";
import debounce from "lodash/debounce";
import { Field, Form } from "vee-validate";
import { ref, toRefs } from "vue";
import { useRoute } from "vue-router";
import { object, string } from "yup";
interface Props {
  data?: any;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'TenantUserCRUD.Form',
  props: {
    data: {}
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;
const { data } = toRefs(props);

const route = useRoute();
const schema = object({
  Firstname: string().required().label("First Name"),
  Lastname: string().required().label("Last Name"),
  Email: string().required().email().label("Email"),
  PhoneNumber: string().max(10).required().label("Phone Number"),
});
const errorMessageRef = ref("");
const { UserId } = route.params;
const onSubmit = async (values: any) => {
  if (UserId) {
    const data = { ...values, UserId };
    TenantUserModule.updateUser(data as ITenantUserUpdate);
  }
};
const onValidateEmailUserName = debounce(async (value, field, meta) => {
  const key = field.name as string;
  const data = {
    [key]: value,
    UserId,
  } as IUserVerificationDTO;
  if (meta.valid && value !== "") {
    const messageError = await TenantUserModule.verificationUser(data);
    if (messageError !== "") {
      errorMessageRef.value = messageError;
    }
    return;
  }
  errorMessageRef.value = "";
}, 100);

const __returned__ = { props, data, route, schema, errorMessageRef, UserId, onSubmit, onValidateEmailUserName, get ITenantUserUpdate() { return ITenantUserUpdate }, get IUserVerificationDTO() { return IUserVerificationDTO }, get TenantUserModule() { return TenantUserModule }, get debounce() { return debounce }, get Field() { return Field }, get Form() { return Form }, ref, toRefs, get useRoute() { return useRoute }, get object() { return object }, get string() { return string } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})