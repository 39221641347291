import { defineComponent as _defineComponent } from 'vue'
import BaseSvgIcon from "@/components/BaseSvgIcon.vue";
import TenantUserModule from "@ems/containers/Tenant/TenantUser.module";
import { Field, Form } from "vee-validate";
import { ref } from "vue";
import { useRoute } from "vue-router";
import { object, ref as yupRef, string } from "yup";


export default /*@__PURE__*/_defineComponent({
  __name: 'TenantUserUpdatePassword.Form',
  setup(__props, { expose: __expose }) {
  __expose();

const isPassword = ref("password");
const isConfirmPassword = ref("password");
const handleShowPassword = (value = "text", type = "isPassword") => {
  type === "isPassword"
    ? (isPassword.value = value)
    : (isConfirmPassword.value = value);
};
const IsAutogenerate = ref(true);

const route = useRoute();
const schema = object({
  NewPassword: string()
    .required()
    .min(8)
    .max(64)
    .matches(
      /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])/,
      "Your password must include a combination of numbers, upper, lower, case letters and symbols"
    )
    .label("Password"),
  ConfirmNewPassword: string()
    .required("Confirm Password is required")
    .min(6)
    .oneOf([yupRef("NewPassword"), null], "Passwords must match"),
});

const { UserId } = route.params;
const onSubmit = async (values: any, actions: any) => {
  const data = !IsAutogenerate.value
    ? { ...values, UserId, IsAutogenerate: IsAutogenerate.value }
    : { UserId, IsAutogenerate: IsAutogenerate.value };
  TenantUserModule.updatePasswordUser(data as any);
  actions.resetForm();
};

const __returned__ = { isPassword, isConfirmPassword, handleShowPassword, IsAutogenerate, route, schema, UserId, onSubmit, BaseSvgIcon, get TenantUserModule() { return TenantUserModule }, get Field() { return Field }, get Form() { return Form }, ref, get useRoute() { return useRoute }, get object() { return object }, get yupRef() { return yupRef }, get string() { return string } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})