import { defineComponent as _defineComponent } from 'vue'
import {
  computeTabName,
  useHash,
  useRedirectOnBeforeRouteUpdate,
  useRedirectOnMounted,
} from "@/utils/hooks";

import TenantUserModule from "@ems/containers/Tenant/TenantUser.module";
import TenantUserCRUD from "@ems/containers/Tenant/TheForm/TenantUserCRUD.Form.vue";
import TenantUserUpdatePassword from "@ems/containers/Tenant/TheForm/TenantUserUpdatePassword.Form.vue";
import { computed, onUnmounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import {
  HASH_EDIT_USER_INFORMATION,
  TAB_EDIT_USER_INFORMATION,
} from "@ems/constants";


export default /*@__PURE__*/_defineComponent({
  __name: 'DetailEditUser',
  setup(__props, { expose: __expose }) {
  __expose();

const tenantUserData = computed(() => TenantUserModule.dataTenantUserGetter);

const route = useRoute();
const router = useRouter();
const { TenantId, UserId }: { TenantId?: string; UserId?: string } =
  route.params;

useRedirectOnBeforeRouteUpdate(
  TenantUserModule.getTenantUser,
  "UserId",
  `/tenant/${TenantId}/user-management`
);

useRedirectOnMounted(
  TenantUserModule.getTenantUser,
  { TenantId, UserId },
  `/tenant/${TenantId}/user-management`
);

onUnmounted(() => {
  TenantUserModule.resetTenantUserEmpty();
});

useHash(HASH_EDIT_USER_INFORMATION);
const handleTabClick = (tabs: string) => {
  router.replace({ hash: `#${tabs}` });
};

const __returned__ = { tenantUserData, route, router, TenantId, UserId, handleTabClick, get computeTabName() { return computeTabName }, get useHash() { return useHash }, get useRedirectOnBeforeRouteUpdate() { return useRedirectOnBeforeRouteUpdate }, get useRedirectOnMounted() { return useRedirectOnMounted }, get TenantUserModule() { return TenantUserModule }, TenantUserCRUD, TenantUserUpdatePassword, computed, onUnmounted, get useRoute() { return useRoute }, get useRouter() { return useRouter }, get HASH_EDIT_USER_INFORMATION() { return HASH_EDIT_USER_INFORMATION }, get TAB_EDIT_USER_INFORMATION() { return TAB_EDIT_USER_INFORMATION } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})